import axios from "axios";

const baseURL = process.env.NODE_ENV === 'development' ? 'http://localhost:8000' : process.env.API_LOCATION;
const axiosInstance = axios.create({
    baseURL: baseURL
});

axiosInstance.interceptors.response.use(response => response, error => {

    console.error('API call error:', error);
    return Promise.reject(error);
});
export default axiosInstance;