import { Box, Button, Paper, TextField, Typography, styled } from '@mui/material';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Unstable_Grid2';
import React, { ChangeEvent, useContext, useState } from 'react';
import { AuthContext } from '../auth/AuthContext';
import { GameConfig, PlayerOptionRequest, PlayerOptionResult, Question } from '../interfaces';
import axios from './../defaults';
import AddPlayerOption from './AddPlayerToQuestion';


interface OptionsProp {
    gameProp: GameConfig,
    questionProp: Question,
    optionsProp: PlayerOptionResult[],
}

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

function OptionsModal({optionsProp, gameProp, questionProp}: OptionsProp)  {
    const [isOpen, setIsOpen] = useState(false);
    const [optionsResults, setOptionsResults] = useState<PlayerOptionResult[]>([]);
    const [newOptions, setNewOptions] = useState<PlayerOptionRequest[]>([]);
    const authContext = useContext(AuthContext);

    const handleOpen = () => {
        setOptionsResults(optionsProp);
        setIsOpen(true)
    };
    const handleClose = () => {
        setIsOpen(false);
    }

    const addPlayerOption = (newOption: PlayerOptionRequest) => {
        setNewOptions(prevOptions => [...prevOptions, newOption]);
    };

    const removePlayerOption = (index: number) => {
        setNewOptions(prevOptions => prevOptions.filter((_, idx) => idx !== index));
    };


    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        
        if (newOptions.length === 0) {
            return;
        }
        try {
            const user = authContext.user;
            if (user === null) {
                throw new Error('Need a an admin user object to make admin calls');
            }
            const idToken = await user.getIdToken();
            console.log("adding option");
            const data = {
                "playerOptions": newOptions,
              };
            const response = await (axios.post(`/internal/games/${gameProp.gameConfigUid}/question/${questionProp.uid}`, data, { 
                headers: {
                    "Authorization" : `Bearer ${idToken}`
                }
            }))
            if (response.status === 200) {
                setNewOptions([]);
                const results = response.data as PlayerOptionResult[]
                setOptionsResults(optionsResults.concat(results));
            }
        }catch (error) {
            console.error('Error fetching data: ', error);
        } finally {
            handleClose();
        }
    };
    const handleScoreChanged = async (playerId: string, e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const user = authContext.user;
        console.log(playerId, e.target.value)
        if (user === null) {
            throw new Error('Need a an admin user object to make admin calls');
        }
        const idToken = await user.getIdToken();
        try {
            const response = await axios.patch(`/internal/games/${gameProp.gameConfigUid}/question/${questionProp.uid}/score`, {
                playerOptionUid: playerId,
                score: +(e.target.value)
            },
            {
                headers: {
                    "Authorization" : `Bearer ${idToken}`
                }
            })
        if (response.status === 200) {
            
            console.log('Data sent successfully');
          } else {
            console.error('Failed to send data');
          }
        } catch (error) {
          console.error('Error sending data:', error);
        }
      }

    return (
        <>
            <Button variant="outlined" color="primary" onClick={handleOpen}>
                Options
            </Button>
            <Modal open={isOpen} onClose={handleClose}>
                <Box 
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        width: 300,
                    }}
                >
                    <Typography variant="h6" gutterBottom>Options</Typography>
                    <Typography>Existing Options</Typography>
                    <form onSubmit={handleSubmit}>
                        {optionsResults.map((opt, idx) => (
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 1 }} justifyContent="center" alignItems="center">
                                <Grid xs={1}>
                                    <Typography>{idx + 1}</Typography>
                                </Grid>
                                <Grid xs={7}>
                                    <Item>{`${opt.playerName} vs ${opt.opponentAbbreviation}`}</Item>
                                </Grid>
                                <Grid xs={3}>
                                    <TextField
                                    variant="outlined"
                                    label={questionProp.abbreviation}
                                    margin="normal"
                                    type="number"
                                    defaultValue={opt.statValue}
                                    onChange={e => handleScoreChanged(opt.uid, e)}
                                    />
                                </Grid>
                            </Grid>)
                        )}
                        <Typography>New Options</Typography>
                        {newOptions.map((opt, index) => (
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} justifyContent="center" alignItems="center">
                                <Grid md={8}>
                                    <Item>{`${opt.playerUid} vs ${opt.opponentUid}`}</Item>
                                </Grid>
                                <Grid md={4}>
                                    <button onClick={() => removePlayerOption(index)}>Remove</button>
                                </Grid>
                            </Grid>)
                        )}
                        
                        <AddPlayerOption options = {newOptions} onAdd={addPlayerOption}/>
                        <Button type="submit" variant="contained" color="primary">
                            Submit
                        </Button>
                    </form>
                </Box>
            </Modal>
        </>
    );
};

export default OptionsModal;
