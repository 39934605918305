import { Box, Grid, List, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import React, { useContext, useState } from 'react';
import { AuthContext } from '../auth/AuthContext';
import axios from './../defaults';
import AddPlayerOption from './AddPlayerToQuestion';
import { PlayerOptionRequest } from '../interfaces';

interface QuestionModalProps {
    gameUid: string;
}

function QuestionModal({gameUid}: QuestionModalProps)  {
    const [isOpen, setIsOpen] = useState(false);
    const [questionText, setQuestionText] = useState('');
    const [selectionText, setSelectionText] = useState('Select 1');
    const [abbr, setAbbr] = useState('');
    const [sportsRadarStatName, setSportsRadarStatName] = useState('');
    const [options, setOptions] = useState<PlayerOptionRequest[]>([]);
    
    const handleOpen = async () => {
        setIsOpen(true)
    };
    const authContext = useContext(AuthContext);
    const handleClose = () => {
        setIsOpen(false);
    }
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        // Your logic to create a new game with the input (gameName)
        try {
            const user = authContext.user;
            if (user === null) {
                throw new Error('Need a an admin user object to make admin calls');
            }
            const idToken = await user.getIdToken();
            console.log("Creating question with name:", questionText, options);
            const data = {
                "questionText": questionText,
                "selectionText": selectionText,
                "statAbbreviation": abbr,
                "sportsRadarStatName": sportsRadarStatName,
                "playerOptions": options,
              };
            await (axios.post(`/internal/games/${gameUid}/question`, data, { 
                headers: {
                    "Authorization" : `Bearer ${idToken}`
                }
            }))
        }catch (error) {
            console.error('Error fetching data: ', error);
        } finally {
            handleClose();
        }
    };

    const addPlayerOption = (newOption: PlayerOptionRequest) => {
        setOptions(prevOptions => [...prevOptions, newOption]);
    };

    const removePlayerOption = (index: number) => {
        setOptions(prevOptions => prevOptions.filter((_, idx) => idx !== index));
    };


    return (
        <>
            <Button variant="contained" color="primary" onClick={handleOpen}>
                Create Question
            </Button>
            <Modal open={isOpen} onClose={handleClose}>
                <Box 
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        width: 300,
                    }}
                >
                    <Typography variant="h6" gutterBottom>Create Question</Typography>
                    <form onSubmit={handleSubmit}>
                        <Grid spacing={1}>
                            <Grid xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Question Text"
                                    value={questionText}
                                    onChange={(e) => setQuestionText(e.target.value)}
                                    margin="normal"
                                />
                            </Grid>
                            <Grid xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Selection Text"
                                    value={selectionText}
                                    onChange={(e) => setSelectionText(e.target.value)}
                                    margin="normal"
                                />
                            </Grid>
                            <Grid xs={6}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Stat Abbreviation"
                                    value={abbr}
                                    onChange={(e) => setAbbr(e.target.value)}
                                    margin="normal"
                                />
                            </Grid>
                            <Grid xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Sports Radar Stat Name"
                                    value={sportsRadarStatName}
                                    onChange={(e) => setSportsRadarStatName(e.target.value)}
                                    margin="normal"
                                />
                            </Grid>
                            <AddPlayerOption options = {options} onAdd={addPlayerOption} />
                            <List sx={{ borderRadius: 1 }}>
                                {options.map((option, index) => (
                                    <div key={index}>
                                        {option.playerUid}
                                        <button onClick={() => removePlayerOption(index)}>Remove</button>
                                    </div>
                                ))}
                            </List>
                        </Grid>
                    <Button type="submit" variant="contained" color="primary">
                        Create
                    </Button>
                    </form>
                </Box>
            </Modal>
        </>
    );
};

export default QuestionModal;
